.flex-right {
  width: 70%;
  height: 100vh;
  overflow-y: auto;
  border-bottom: 10px solid rgb(74, 223, 131);
  background-color: rgb(248, 249, 250);
}
.flex-right .default-content {
  text-align: center;
  margin-top: 70px;
  color: rgb(149, 150, 150);
}
.flex-right .default-content::after {
  border: 5px solid #4adf83;
}

.flex-right .default-content .short-description p {
  margin-top: 20px;
  padding-left: 250px;
  width: 75%;
  user-select: none;
}
.flex-right .default-content .main-image img {
  width: 250px;
}

.flex-right .default-content h1 {
  margin-top: 28px;
  font-size: 30px;
  font-weight: 300;
  user-select: none;
}
.flex-right .default-content .divider-line {
  margin: 30px 235px;
}
.flex-right .default-content .description {
  display: flex;
  justify-content: center;
  user-select: none;
}
.flex-right .default-content .description a {
  text-decoration: none;
  color: #07bc4c;
}
