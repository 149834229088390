.second-last-section {
  margin: 30px;
}
.whatsapp-groups {
  display: inline-block;
}
.whatsapp-groups a {
  font-weight: bold;
  font-size: 17px;
  margin: 0 7px;
  text-decoration: none;
}
.whatsapp-groups a:hover {
  color: var(--background-color-1);
}
.footer {
  background-color: rgb(0, 54, 108);
  display: flex;
}
.footer a {
  text-decoration: none;
  color: white;
}
.footer-left-content {
  color: white;
  margin: 0 50px;
  display: flex;
  font-size: 13px;
  padding: 15px 0;
  width: 100%;
}
.footer-right-content {
  padding: 12px 0;
  position: absolute;
  right: 5%;
}
@media only screen and (max-width: 840px) {
  .footer-right-content {
    display: none;
  }
}
.each-link {
  margin: 0 5px;
}
.horizontal-line {
  margin-top: 30px;
}
