.middle-block {
  background-image: url("../../images/WhatsappBgImage.png");
  overflow-y: auto;
  height: 81vh;
}
.middle-block::-webkit-scrollbar {
  width: 10px;
}
.middle-block::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.middle-part {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-direction: column;
  padding: 25px;
}
.middle-part .left-side {
  background-color: rgb(255, 255, 255);
  margin: 10px;
  margin-left: 0px;
  padding: 20px;
  border-radius: 15px;
  border-top-left-radius: 0px;
}
.middle-part .right-side {
  background-color: rgb(220, 248, 198);
  margin: 10px;
  margin-left: auto;
  padding: 20px;
  border-radius: 15px;
  border-top-right-radius: 0px;
}
.time {
  position: relative;
  top: 10px;
  font-size: 13px;
  padding-left: 10px;
  font-family: monospace;
  color: rgba(0, 0, 0, 0.45);
}
