.breadcrumb {
  margin: 10px 0px;
  background-color: #f9f9f9;
  border-top: 2px ridge thistle;
  border-bottom: 2px ridge thistle;
  margin-top: 80px;
}
.inner-breadcrumb p {
  display: inline;
  margin-right: 5px;
  padding: 10px;
}

.inner-breadcrumb p a {
  text-decoration: none;
}
.inner-breadcrumb p a:hover {
  text-decoration: none;
}
