.footer-component {
  height: 60px;
  background-color: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}

.footer-component .ist-block img {
  margin: 10px;
  padding: 10px;
}
.footer-component .ist-block img:hover {
  cursor: pointer;
  background-color: rgb(213, 213, 213);
  border-radius: 20px;
}

.footer-component .second-block .input-message {
  outline: none; /* to remove the default border when we focus  */
  width: 53vw;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 25px;
}

.footer-component .third-block img {
  margin: 10px;
  padding: 10px;
}
.footer-component .third-block img:hover {
  cursor: pointer;
  background-color: rgb(213, 213, 213);
  border-radius: 20px;
}
