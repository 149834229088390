.bodyComponent {
  margin: 0 30px;
}
.three-links .content h4 a {
  color: #a91f36;
  text-decoration: none;
}
.three-links .content {
  display: inline;
  float: left;
  width: 30%;
  height: 70px;
  padding: 10px;
  border: 1px solid black;
  margin: 10px;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .three-links .content {
    width: 100%;
  }
}

.three-links .content .inner-content {
  display: flex;
}
.three-links .content .inner-content .link-heading {
  padding-left: 10px;
  font-size: 15px;
  font-weight: bold;
}
.three-links .content .link-heading a:hover {
  color: blue;
}
.clearfix {
  clear: both;
}
.heading {
  margin-top: 20px;
  color: blue;
}
.bodyComponent .description h5 {
  margin-top: 20px;
  font-weight: bold;
  color: #a91f36;
}
.bodyComponent .description p {
  font-size: 20px;
  margin-top: 20px;
}

.last-content p {
  font-weight: bold;
}
