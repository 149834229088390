.header-component {
  height: 60px;
  background-color: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-component .profile-pic {
  display: flex;
  justify-content: space-between;
}

.header-component .profile-pic img {
  margin-left: 20px;
  width: 50px;
}
.header-component .profile-pic .user-name {
  margin-left: 20px;
  width: 50px;
  margin-top: 6px;
}

.header-component .right-side-icons img {
  margin: 10px;
  padding: 10px;
}

.header-component .right-side-icons img:hover {
  cursor: pointer;
  background-color: rgb(213, 213, 213);
  border-radius: 20px;
}
