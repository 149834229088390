* {
  box-sizing: border-box;
}

#header {
  background-color: rgb(0, 86, 157);
  margin: 0;
  position: fixed;
  top: 0;
}
.links {
  color: white;
  border-bottom: 5px solid red;
}
.links ul {
  list-style-type: none;
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */

  margin: 0;
}
.links ul li {
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  display: inline;
  float: left;

  transition: background-color 0.3s;
}

.links ul li:hover {
  background-color: orangered;
}
.links ul li a {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
}
#main-link a {
  padding: 0;
}
.links ul .menu-bar {
  display: none;
}
@media only screen and (max-width: 500px) {
  .hide {
    display: none !important;
  }
  .menu-bar {
    display: block !important;
  }
}
.links ul .menu-bar {
  margin: 10px;
  font-size: 15px;
}
.links ul .menu-bar:hover {
  background-color: transparent;
}
#main-link:hover {
  background-color: transparent;
}
.clearfix {
  clear: both;
}
