.body {
  background-color: rgb(237, 239, 244);
  padding: 30px;
}
.body .heading {
  color: rgb(101, 101, 101);
  font-weight: 700;
}
.body ul {
  list-style-type: none;
  padding: 0px;
}
.body ul li {
  font-size: 25px;
}
.body ul li a:hover {
  color: rgb(13, 142, 207);
}

.body ul li a {
  text-decoration: none;
  color: rgb(51, 51, 51);
}
