:root {
  --whats-app-background-color: rgb(237, 237, 237);
}
.flex-left {
  width: 30%;
  background-color: var(--whats-app-background-color);
  border-right: 1px solid gainsboro;
}
.flex-left .top-header {
  text-align: center;
}
.flex-left .top-header .top-navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  align-items: center;
  height: 60px;
}
.flex-left .top-header .top-navbar .first-block img {
  width: 45px;
}
.flex-left .top-header .top-navbar .second-block img {
  margin: 10px;
  padding: 7px;
  width: 40px;
}

.flex-left .top-header .top-navbar .second-block img:hover {
  cursor: pointer;
  background-color: rgb(213, 213, 213);
  border-radius: 20px;
}

.flex-left .top-header .search-block {
  background-color: rgb(246, 246, 246);
  height: 58px;
  border-bottom: 1px solid #919191;
}
.flex-left .top-header .search-block .first-item {
  background-color: white;
}
.flex-left .top-header .search-block .first-item:hover {
  cursor: pointer;
}
.flex-left .top-header .search-block .third-item:hover {
  cursor: pointer;
}
.flex-left .top-header .search-block .third-item .loading-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.flex-left .top-header .search-block .block {
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: white;
  height: -webkit-fill-available;
  padding-top: 5px;
}

.flex-left .top-header .toggle-search-block {
  padding: 1px 5px;
}
.flex-left .top-header .search-block .toggle-block {
  transition: margin 0.2s, border-radius 0.2s;

  margin: 5px 10px;
  border-radius: 50px;
  padding-left: 40px;
}

.flex-left .top-header .input-user {
  width: 80%;
  border: none;
  padding: 10px;
  padding-bottom: 15px;
  padding-right: 40px;
  border-radius: 50px;
}
.flex-left .top-header .input-user:focus {
  outline: 0;
}

.flex-left .top-header .email-list {
  background-color: white;
  overflow-y: auto;
  height: 81vh;
}
.selected-user {
  cursor: pointer;
  background-color: #f5f5f5;
}

.flex-left .top-header .email-list .list:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.flex-left .top-header .email-list .list .email-block {
  display: flex;
  margin: 0px 20px;
  align-items: baseline;
}

.flex-left
  .top-header
  .email-list
  .list
  .email-block
  .profile-block
  .profile-pic {
  width: 75px;
  height: 75px;
}

.flex-left .top-header .email-list .list .email-block .user-name {
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  margin-left: 15px;
  text-align: left;
  padding: 10px;
}
.flex-left .top-header .email-list .list .email-block .user-name .email {
  margin: 22px 12px;
}

.flex-left .top-header .email-list::-webkit-scrollbar {
  width: 10px;
}

.flex-left .top-header .email-list::-webkit-scrollbar-thumb {
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
}
