.header_component {
  width: 100%;
  background-color: rgb(33, 34, 44);
  position: sticky;
  top: 0px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first_block {
  display: flex;
  align-items: center;
}

.first_block img {
  width: 100px;
}
.first_block .url {
  font-size: 22px;
  margin-top: 15px;
  color: white;
}

.second_block input {
  width: 500px;
  height: 50px;
  margin: 20px;
  background-color: rgb(47, 48, 64);
  padding: 20px;
  border: 3px solid rgb(85, 85, 85);
  outline: none;
  color: white;
}
.second_block input:focus {
  border: 3px solid rgb(30, 170, 241);
}
.second_block img {
  width: 35px;
  position: relative;
  right: 65px;
  bottom: 2px;
}
