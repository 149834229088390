:root {
  --special-color: #919191;
}
.flex-container {
  display: flex;
  flex-direction: row;
  line-height: 1;
}

@media only screen and (max-width: 900px) {
  .flex-container {
    display: flex;
    flex-direction: column;
  }
}

.contact-card {
  display: flex;
  padding: 10px;
  color: #ffffff;
  background-color: rgb(42, 84, 104);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  max-width: 500px;

  margin-bottom: 10px;
}

.contact-card p {
  margin-left: 10px;
  margin-top: 0;
}

button {
  margin-left: 10px;
  margin-bottom: 10px;
}
