/*  these are the global variables we can use */
:root {
  --background-color-1: rgb(136, 0, 0);
  --background-color-2: rgb(158, 140, 22);
  --background-color-3: rgb(9, 71, 35);
  --background-color-4: rgb(240, 94, 10);
}
.bg1 {
  background-color: var(--background-color-1);
}
.bg2 {
  background-color: var(--background-color-2);
}
.bg3 {
  background-color: var(--background-color-3);
}
.bg4 {
  background-color: var(--background-color-4);
}
.table-content {
  margin-right: 40px;
}
.table {
  border: 1px solid black;
  margin: 30px 0px;
}
.table-heading {
  color: white;
}
.table-heading th {
  font-size: 17px;
  font-weight: bold;
}
.text-align-center {
  text-align: center;
}

.table tr td {
  border: 1px solid black;
}
.table .table-link a {
  text-decoration: none;
  font-weight: bold;
}
.table .table-link a:hover {
  color: var(--background-color-1);
}
.horizontal-line {
  margin-top: 30px;
}
